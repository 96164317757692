import { useRecoilValue, useSetRecoilState } from 'recoil'
import { fetchingState } from './atom'

const useFetching = () => {
  const fetching = useRecoilValue(fetchingState)
  return { fetching }
}
const useEndFetching = () => {
  const setter = useSetRecoilState(fetchingState)
  const endFetching = () => {
    setter(false)
  }
  return { endFetching }
}

export { useFetching, useEndFetching }
