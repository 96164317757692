import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userMailState } from './atom'

const useUserMail = () => {
  const userMail = useRecoilValue(userMailState)
  return { userMail }
}
const useUserMailSetter = () => {
  const setUserMail = useSetRecoilState(userMailState)
  return { setUserMail }
}

export { useUserMail, useUserMailSetter }
