import Image from 'next/image';
import Link from 'next/link';
import router from 'next/router';
import { Routes } from 'src/utils/routes';
import styles from './style.module.scss';

const Logo = () => {
  return (
    <button className={styles.logo}
      onClick={(): void => {
        router.push(Routes.HOME)
      }}
    >
      <span className="utilsPc">
        <Image
          src="/images/logo_row.png"
          width={325.25}
          height={50}
          priority={true}
          alt="ワーケーションフレンドリーパス produced by W@F"
        />
      </span>
      <span className="utilsSp">
        <Image
          src="/images/logo_row_sp.png"
          width={183}
          height={48}
          priority={true}
          alt="ワーケーションフレンドリーパス produced by W@F"
        />
      </span>
    </button>
  )
}

export { Logo }
