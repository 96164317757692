// 以下エラーについて
// Duplicate atom key "***". This is a FATAL ERROR in production. But it is safe to ignore this warning if it occurred because of hot module replacement.
// https://file-translate.com/ja/blog/recoil-duplicate-atom-key

export enum RecoilAtomKeys {
  ACCESS_TOKEN = 'accessToken',

  LOADER_DISPLAY_STATE = 'loaderDisplayState',

  TOAST_DISPLAY_STATE = 'toastState',

  USER_FETCHING = 'userFetching',
  USER_UID = 'userUid',
  USER_REGISTERED = 'userRegistered',
  USER_NAME = 'userName',
  USER_MAIL = 'userMail',
  USER_PHOTO = 'userPhoto',
  USER_SERVICE_PAGE = 'userServicePage',
  PAGE_ID = 'pageId',
}

export enum RecoilSelectorKeys {
  USER_SIGNED_IN = 'userSignedIn',
}
// GET_SELECTOR = 'getSelector',
