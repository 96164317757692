import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  useLoaderState,
  useLoaderStateSetter,
} from 'src/store/loaderDisplayState'

import styles from './style.module.scss'

/*
<section className={styles.mailLoader}>
          <h4 className={styles.mailLoaderHead}>メールを送信しました</h4>
          <p className={styles.mailLoaderText}>
            ご入力いただいたメールアドレスに
            <br />
            ログイン用のURLを送信しました。
            <br />
            メールをご確認ください。
          </p>
          <small className={styles.mailLoaderSmall}>
            ※ログイン用のURLを開く際は
            <br />
            現在ご利用のブラウザで確認をお願いいたします。
          </small>
          <Link href="/">
            <a className={styles.mailLoaderLink}>
              <b>TOPへ戻る</b>
            </a>
          </Link>
        </section> */

const Loader = () => {
  const { isLoaderShown } = useLoaderState()
  const { setLoaderState } = useLoaderStateSetter()

  if (!isLoaderShown) {
    return <div></div>
  }

  const text = isLoaderShown !== '' ? isLoaderShown : '読み込み中...'

  return (
    <div className={styles.loader} data-active="true">
      <div className={styles.loaderInner}>
        <div className={styles.loaderCircle}>
          <svg
            width="160"
            height="160"
            viewBox="0 0 160 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M80 9.77738C80 4.37748 75.6061 -0.0616674 70.2465 0.596642C55.2583 2.4376 41.0243 8.49501 29.2485 18.1592C14.9413 29.9008 5.14799 46.24 1.53717 64.3928C-2.07364 82.5456 0.721485 101.389 9.44629 117.712C18.1711 134.035 32.2858 146.827 49.3853 153.91C66.4849 160.993 85.5113 161.928 103.223 156.555C120.934 151.183 136.235 139.835 146.518 124.446C156.8 109.056 161.429 90.5779 159.615 72.1586C158.122 56.9983 152.34 42.6501 143.043 30.7501C139.719 26.4948 133.473 26.5268 129.655 30.3451V30.3451C125.837 34.1634 125.914 40.317 129.067 44.7005C135.252 53.2981 139.105 63.4213 140.154 74.0753C141.525 87.9923 138.028 101.954 130.258 113.582C122.489 125.209 110.928 133.783 97.5463 137.842C84.1642 141.902 69.7884 141.196 56.8686 135.844C43.9488 130.493 33.2842 120.827 26.6921 108.494C20.0999 96.1606 17.988 81.9233 20.7162 68.2077C23.4444 54.4921 30.8439 42.1468 41.6539 33.2752C49.9295 26.4837 59.8117 22.0496 70.2648 20.3438C75.5942 19.474 80 15.1773 80 9.77738V9.77738Z"
              fill="white"
            />
          </svg>
        </div>
        <p
          className={styles.loaderText}
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      </div>
    </div>
  )
}

export { Loader }
