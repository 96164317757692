import { FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyABxT3ytU7USM3WC_uz9ZxFcy3OMmRoVao",
  authDomain: "waf-pass.firebaseapp.com",
  databaseURL: "https://waf-pass-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "waf-pass",
  storageBucket: "waf-pass.appspot.com",
  messagingSenderId: "863537603469",
  appId: "1:863537603469:web:d6db71cd574d56c70213c4",
  measurementId: "G-YPB5RNDR65"
};

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

export { db }
