import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userPhotoState } from './atom'

const useUserPhoto = () => {
  const userPhoto = useRecoilValue(userPhotoState)

  return { userPhoto }
}
const useUserPhotoSetter = () => {
  const setUserPhoto = useSetRecoilState(userPhotoState)
  return { setUserPhoto }
}

export { useUserPhoto, useUserPhotoSetter }
