import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useFetching } from './userFetching'
import { userUidState } from './atom'

const useUserUid = () => {
  const userUid = useRecoilValue(userUidState)
  return { userUid }
}
const useUserUidSetter = () => {
  const setUserUid = useSetRecoilState(userUidState)
  return { setUserUid }
}
const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const uid = useRecoilValue(userUidState)
  const { fetching } = useFetching()
  useEffect(() => {
    if (fetching) {
      return
    }
    setIsAuthenticated(!!uid)
  }, [uid])
  return { isAuthenticated }
}

export { useUserUid, useUserUidSetter, useIsAuthenticated }
