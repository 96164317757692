import { getAuth, signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useIsRegisteredSetter } from 'src/store/userRegistered';
import { Routes } from 'src/utils/routes';

import { useUserSignIn } from './signIn';

const useUserSignOut = () => {
  const router = useRouter()
  const userSignIn = useUserSignIn()
  const { setIsRegistered } = useIsRegisteredSetter()
  const userSignOut = () => {
    signOut(getAuth())
      .then(() => {
        userSignIn({
          uid: '',
          name: '',
          mail: '',
          photo: ''
        })
        setIsRegistered(false)
        router.push(Routes.HOME)
      })
      .catch((error) => {
        // #FIXME sentry？
        if (process.env.NEXT_PUBLIC_STAGING) {
          return
        }
        console.error(error)
      })
  }
  return userSignOut
}

export { useUserSignOut }
