import 'ress';
import 'src/assets/styles/base.scss';
import 'src/assets/styles/variables.scss';
import 'src/utils/firebase/initializeFirebase';
import { RecoilRoot } from 'recoil';
import { DefaultSeo } from 'next-seo';
import { Wrapper } from 'src/components/wrapper';
import NextNprogress from 'nextjs-progressbar'
import type { AppProps } from 'next/app'
import { Meta } from 'src/utils/meta';
import Head from 'next/head';
import { useEffect } from 'react';
import { GaCookieOptIn } from 'src/components/gaCookieOptIn';
import Script from 'next/script'

const App = ({ Component, pageProps, router }: AppProps) => {
  const handleComplete = () => {
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    router.events.on("routeChangeComplete", handleComplete)
    return () => {
      router.events.off("routeChangeComplete", handleComplete)
    }
  })
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/images/favicon.png" />
      </Head>
      <Script
        id="ga-script"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_GTM_ID}');
          `
        }}
      />
      <DefaultSeo
        defaultTitle={Meta.TITLE}
        description={Meta.DESCRIPTION}
        openGraph={{
          type: "website",
          title: `${Meta.TITLE}`,
          description: `${Meta.DESCRIPTION}`,
          site_name: `${Meta.SITE_NAME}`,
          url: `${Meta.SITE_URL}`,
          images: [
            {
              url: `${Meta.OGP_IMAGE}`,
              width: 1200,
              height: 630,
              alt: `${Meta.TITLE}`,
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: "summary_large_image",
        }}
      />
      <NextNprogress />
      <RecoilRoot>
        <GaCookieOptIn />
        <Wrapper>
          <Component {...pageProps} />
        </Wrapper>
      </RecoilRoot>
    </>
  )
}

export default App
