import Link from "next/link";
import { useEffect, useState } from "react"
import styles from './style.module.scss';

type Props = {
}

const GaCookieOptIn: React.VFC<Props> = ({ }) => {
  const [storageState, setStorageState] = useState('')
  const [isShow, setIsShow] = useState(true)

  useEffect(() => {
    const ID = `${process.env.NEXT_GA_ID}`
    const cookieOptin = localStorage.getItem('ga_cookie_opt_in')
    if (cookieOptin == 'no') {
      // @ts-ignore
      window[`ga-disable-${ID}`] = true
      setStorageState('no')
    } else if (cookieOptin == 'yes') {
      // @ts-ignore
      window[`ga-disable-${ID}`] = false
      setStorageState('yes')
    } else {
      // @ts-ignore
      window[`ga-disable-${ID}`] = true
      setStorageState('no')
    }
  }, [storageState])


  const accept = () => {
    localStorage.setItem('ga_cookie_opt_in', 'yes')
    setStorageState('yes')
    setIsShow(false)
  }

  const deny = () => {
    localStorage.setItem('ga_cookie_opt_in', 'no')
    setStorageState('yes')
    setIsShow(false)
  }

  return (
    <>
      {
        isShow && storageState === 'no' &&
        <div className={styles.CookieBanner}>
          <p className={styles.CookieText}>
            当サイトでは、Cookieを使用して利用者の体験を向上させています。 閲覧を続行する場合は、<br />
            当サイトでCookieの使用に承諾いただいたことになります。詳細については、<Link href="/privacypolicy"><a >プライバシーポリシー</a></Link>をご覧ください。
          </p>
          <div className={styles.CookieBannerButtons}>
            <button
              className={`${styles.CookieBannerButton} ${styles.CookieBannerButtonAccept}`}
              onClick={accept}
            >同意する</button>
            <button
              className={styles.CookieBannerButton}
              onClick={deny}
            >同意しない</button>
          </div>
        </div>
      }
    </>
  )
}

export { GaCookieOptIn }
