// src/store/atoms.ts
import { atom } from 'recoil'
import { RecoilAtomKeys } from 'src/utils/recoil/keys'

export const userPhotoState = atom<string>({
  key: RecoilAtomKeys.USER_PHOTO,
  default: '',
})

export const loaderState = atom<string>({
  key: RecoilAtomKeys.LOADER_DISPLAY_STATE,
  default: '',
})

export const pageIdState = atom<string>({
  key: RecoilAtomKeys.PAGE_ID,
  default: '',
})

export const toastState = atom<string>({
  key: RecoilAtomKeys.TOAST_DISPLAY_STATE,
  default: '',
})

export const fetchingState = atom<boolean>({
  key: RecoilAtomKeys.USER_FETCHING,
  default: true,
})

export const userMailState = atom<string>({
  key: RecoilAtomKeys.USER_MAIL,
  default: '',
})

export const userNameState = atom<string>({
  key: RecoilAtomKeys.USER_NAME,
  default: '',
})

export const isRegisteredState = atom<boolean>({
  key: RecoilAtomKeys.USER_REGISTERED,
  default: false,
})

export const userUidState = atom<string>({
  key: RecoilAtomKeys.USER_UID,
  default: '',
})

export const useServicePageState = atom<string>({
  key: RecoilAtomKeys.USER_SERVICE_PAGE,
  default: '',
})
