// _(アンダースコア)1つは単語区切り
// __2つはパス構造を表す

export enum Routes {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_IN_SEND_EMAIL = '/sign-in/send-email',
  SIGN_UP = '/sign-up',
  MY_PAGE = '/mypage',
  MANAGE_PASS = '/manage-pass',
  PASS_REQUEST = '/pass-request',
  RESERVARTION_REQUEST = '/reservation-request',
  RESERVARTION_REQUEST_DONE = '/reservation-request/done',
  SPOT = '/spot',
  SPOT__SERVICES = '/services',
  SPOT__SERVICES__DONE = '/done',
  SERVICE = '/service',
  HOTEL = '/hotel',
  ABOUT = '/about',
  NEWS = '/news',
  PRIVACY_POLICY = '/privacypolicy',
  TERMAS = '/terms',
  CAMPAIGN = '/campaign',
}
