import Link from 'next/link'
import { useUserUid } from 'src/store/userUid'
import { Routes } from 'src/utils/routes'
import { Logo } from '../Logo'
import { FC, useEffect, useState } from 'react'

import styles from './style.module.scss'
import { Menu } from './menu'
import { useRouter } from 'next/router'

type Props = {
  defaultOpen?: boolean
  onOpen?: () => void
  disable?: boolean
}

const Header: FC<Props> = ({
  onOpen,
  defaultOpen = false,
  disable = false,
}) => {
  const router = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(defaultOpen)
  const { userUid } = useUserUid()

  const handleComplete = () => {
    setIsMenuOpen(false)
  }
  useEffect(() => {
    router.events.on('routeChangeStart', handleComplete)
    return () => {
      router.events.off('routeChangeStart', handleComplete)
    }
  }, [])

  return (
    <header className={styles.header} data-disable={disable}>
      <Logo />
      <div className={styles.headerMenu}>
        <div className={styles.headerUser}>
          {userUid ? (
            <Link href={Routes.MY_PAGE} passHref>
              <a className={styles.headerMypageLink}>
                <span className={styles.headerMypageLinkIcon}>
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path
                      d="M17.6712 3C9.54573 3 2.95117 9.72 2.95117 18C2.95117 26.28 9.54573 33 17.6712 33C25.7966 33 32.3912 26.28 32.3912 18C32.3912 9.72 25.7966 3 17.6712 3ZM17.6712 7.5C20.1147 7.5 22.0872 9.51 22.0872 12C22.0872 14.49 20.1147 16.5 17.6712 16.5C15.2277 16.5 13.2552 14.49 13.2552 12C13.2552 9.51 15.2277 7.5 17.6712 7.5ZM17.6712 28.8C13.9912 28.8 10.7381 26.88 8.83917 23.97C8.88333 20.985 14.7272 19.35 17.6712 19.35C20.6005 19.35 26.459 20.985 26.5032 23.97C24.6043 26.88 21.3512 28.8 17.6712 28.8Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
                <span>マイページ</span>
              </a>
            </Link>
          ) : (
            <Link href={Routes.SIGN_IN} passHref>
              <a className={styles.headerUserLoginButton}>ログイン</a>
            </Link>
          )}
        </div>
        <button
          className={styles.headerButton}
          aria-label="メニュー"
          aria-pressed="mixed"
          onClick={(): void => {
            if (!isMenuOpen) onOpen?.()
            setIsMenuOpen(!isMenuOpen)
          }}
        >
          {!isMenuOpen ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className={styles.open}
            >
              <path d="M2 6H22" />
              <path d="M2 12H22" />
              <path d="M2 18H22" />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className={styles.close}
            >
              <path d="M20.6234 3.37658C21.1255 3.87868 21.1255 4.69275 20.6234 5.19485L5.19485 20.6234C4.69275 21.1255 3.87868 21.1255 3.37658 20.6234C2.87447 20.1213 2.87447 19.3073 3.37658 18.8051L18.8051 3.37658C19.3073 2.87447 20.1213 2.87447 20.6234 3.37658Z" />
              <path d="M3.37658 3.37658C2.87447 3.87868 2.87447 4.69275 3.37658 5.19485L18.8051 20.6234C19.3073 21.1255 20.1213 21.1255 20.6234 20.6234C21.1255 20.1213 21.1255 19.3073 20.6234 18.8051L5.19485 3.37658C4.69275 2.87447 3.87868 2.87447 3.37658 3.37658Z" />
            </svg>
          )}
        </button>
      </div>
      <Menu isMenuOpen={isMenuOpen} />
    </header>
  )
}

export { Header }
