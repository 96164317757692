import { useUserMailSetter } from 'src/store/userMail';
import { useUserNameSetter } from 'src/store/userName';
import { useUserPhotoSetter } from 'src/store/userPhoto';
import { useUserUidSetter } from 'src/store/userUid';

type Props = {
  uid: string
  name: string
  mail: string
  photo: string
}

const useUserSignIn = () => {
  const { setUserUid } = useUserUidSetter()
  const { setUserName } = useUserNameSetter()
  const { setUserMail } = useUserMailSetter()
  const { setUserPhoto } = useUserPhotoSetter()
  const userSignIn = ({ uid, name, mail, photo }: Props) => {
    setUserUid(uid)
    setUserName(name)
    setUserMail(mail)
    setUserPhoto(photo)
  }
  return userSignIn
}

export { useUserSignIn }
