import { getRoutingItems } from 'next/dist/shared/lib/router/utils'

const STORAGE_KEY = 'redirected'

const setRedirectDetector = () => {
  localStorage.setItem(STORAGE_KEY, 'true')
}

const isRedirected = () => {
  return !!localStorage?.getItem(STORAGE_KEY)
}

const removeRedirectDetector = () => {
  localStorage.removeItem(STORAGE_KEY)
}

export { isRedirected, setRedirectDetector, removeRedirectDetector }
