import { useRouter } from 'next/router'
import { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import { useCheckUserAuthenticated } from 'src/plugins/checkUserAuthenticated'
import { useCheckUserRegistered } from 'src/plugins/checkUserRegistered'
import { useUserUid } from 'src/store/userUid'
import FixedRegisterBanner from '../fixedRegisterBanner'

import { Footer } from '../footer'
import { Header } from '../header'
import { Loader } from '../loader'

import styles from './style.module.scss'

type props = {
  children: ReactNode
}

const Wrapper = ({ children }: props) => {
  const { pathname } = useRouter()

  // ログイン
  const { userUid } = useUserUid()
  const checkUserAuthenticated = useCheckUserAuthenticated()
  const checkUserRegistered = useCheckUserRegistered()

  // ロード
  const [loadOnce, setLoadOnce] = useState(false)

  const fireFirstLoad = async () => {
    // await checkUserAuthenticated()
  }
  useEffect(() => {
    if (loadOnce) {
      return
    }
    fireFirstLoad()
    setLoadOnce(true)
  }, [])

  // ユーザー判定
  const fireEachPage = async () => {
    await checkUserAuthenticated()
    await checkUserRegistered()
  }
  useEffect(() => {
    fireEachPage()
  }, [pathname, userUid])

  if (!loadOnce) {
    return (
      <div className={styles.wrapper}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Header
          disable={
            pathname === '/spot/[spotId]/services' || pathname === '/sign-up'
          }
        />
        <div>{children}</div>
        <Footer
          disable={
            pathname === '/spot/[spotId]/services' || pathname === '/sign-up'
          }
        />
        {!userUid && pathname != '/sign-in' && pathname != '/sign-up' && (
          <FixedRegisterBanner />
        )}
      </div>
    </div>
  )
}

export { Wrapper }
