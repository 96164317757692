import styles from './style.module.scss'
import { FC } from 'react'
import Link from 'next/link'
import { Routes } from 'src/utils/routes'

const FixedRegisterBanner: FC = () => {
  return (
    <Link href={Routes.SIGN_IN} passHref>
      <a className={styles.fixedRegisterBanner}>
        無料会員登録・ログイン
      </a>
    </Link>
  )
}

export default FixedRegisterBanner
