import {
  browserLocalPersistence,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  setPersistence,
  User,
} from 'firebase/auth'
import { useRouter } from 'next/router'
import { useLoaderStateSetter } from 'src/store/loaderDisplayState'
import { useEndFetching } from 'src/store/userFetching'
import { isRedirected, removeRedirectDetector } from 'src/utils/isRedirected'
import { useUserSignIn } from './signIn'

const useCheckUserAuthenticated = () => {
  const router = useRouter()
  const userSignIn = useUserSignIn()
  const { setLoaderState } = useLoaderStateSetter()
  const { endFetching } = useEndFetching()

  return async () => {
    if (isRedirected()) {
      setLoaderState('ログイン中')
    }
    const auth = getAuth()

    const authUser = await new Promise<User | null>((resolve) => {
      onAuthStateChanged(auth, (user) => {
        setPersistence(auth, browserLocalPersistence)
        resolve(user)
      })
    })

    if (authUser) {
      userSignIn({
        uid: authUser.uid,
        name: authUser.displayName || '',
        mail: authUser.email || '',
        photo: authUser.photoURL || '',
      })
      setLoaderState('')
      removeRedirectDetector()
      endFetching()
      return
    }
    // signInWithRedirectから流れてきたのであれば
    const redirectResult = await getRedirectResult(auth)
    const resultCurrentUser = redirectResult?.user
    if (!resultCurrentUser) {
      // ログインしていない、signInWithRedirectから流れてきているわけでもない
      userSignIn({
        uid: '',
        name: '',
        mail: '',
        photo: '',
      })
      setLoaderState('')
      removeRedirectDetector()
      endFetching()
      return
    }
    // signInWithRedirectから流れてきた
    userSignIn({
      uid: resultCurrentUser.uid,
      name: resultCurrentUser.displayName || '',
      mail: resultCurrentUser.email || '',
      photo: resultCurrentUser.photoURL || '',
    })
    setLoaderState('')
    removeRedirectDetector()
    endFetching()
  }
}

export { useCheckUserAuthenticated }
