import { useRecoilValue, useSetRecoilState } from 'recoil'
import { loaderState } from './atom'

const useLoaderState = () => {
  const isLoaderShown = useRecoilValue(loaderState)
  return { isLoaderShown }
}
const useLoaderStateSetter = () => {
  const setLoaderState = useSetRecoilState(loaderState)
  return { setLoaderState }
}

export { useLoaderState, useLoaderStateSetter }
