import Link from 'next/link'
import { useIsRegistered } from 'src/store/userRegistered'
import { useIsAuthenticated } from 'src/store/userUid'
import { Routes } from 'src/utils/routes'
import Image from 'next/image'
import styles from './style.module.scss'
import { Logo } from '../Logo'

type Props = {
  disable?: boolean
}

const Footer = ({ disable = false }: Props) => {
  return (
    <footer className={styles.footer} data-disable={disable}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLogo}>
          <Logo />
        </div>
        <nav className={styles.footerMainList}>
          <ul>
            <li>
              <Link href="/spot/page/1" passHref>
                <a>ワフパス特典一覧</a>
              </Link>
            </li>
            <li>
              <Link href="/hotel/page/1">
                <a>宿泊プラン一覧</a>
              </Link>
            </li>
            <li>
              <Link href="/campaign">
                <a>交通キャンペーン一覧</a>
              </Link>
            </li>
          </ul>
        </nav>
        <nav className={styles.footerSubList}>
          <ul>
            <li>
              <Link href="/about" passHref>
                <a>ワフパスとは</a>
              </Link>
            </li>
            <li>
              <Link href="/news/page/1" passHref>
                <a>お知らせ</a>
              </Link>
            </li>
            <li>
              <Link href="/faq" passHref>
                <a>よくある質問</a>
              </Link>
            </li>
            <li>
              <Link href="/terms" passHref>
                <a>利用規約</a>
              </Link>
            </li>
            <li>
              <Link href="/privacypolicy" passHref>
                <a>プライバシーポリシー</a>
              </Link>
            </li>
            <li>
              <a
                href="https://workation-fukuoka.jp/contact/"
                target="_blank"
                rel="noreferrer noopener"
              >
                お問い合わせ
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.footerLogoLink}>
          <a
            href="https://workation-fukuoka.jp/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image
              src="/images/w@f_logo.png"
              width={112}
              height={37}
              alt="W@F"
              priority={false}
            />
          </a>
          <ul className={styles.footerSns}>
            <li>
              <a
                href="https://www.facebook.com/workation.fukuoka"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Image
                  src="/images/icon_sns_facebook.png"
                  width={20}
                  height={20}
                  alt="Facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/workation.fukuoka/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Image
                  src="/images/icon_sns_instagram.png"
                  width={20}
                  height={20}
                  alt="Instagram"
                />
              </a>
            </li>
            <li>
              <a
                href="https://lit.link/workationfukuoka"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Image
                  src="/images/icon_sns_litlink.png"
                  width={83}
                  height={20}
                  alt="Lit.Link"
                />
              </a>
            </li>
          </ul>
          <div className={styles.footerLogoLinkList}>
            <a
              href="https://www.city.fukuoka.lg.jp/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src="/images/fukuoka_logo.png"
                width={68}
                height={19}
                alt="福岡市公式"
              />
            </a>
            <a
              href="https://www.welcome-fukuoka.or.jp/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src="/images/fcvb_logo.png"
                width={68}
                height={19}
                alt="福岡観光コンベンションビューロー"
              />
            </a>
          </div>
        </div>
        <p className={styles.footerCopylight}>
          <small>&copy; WORKATION@FUKUOKA</small>
        </p>
      </div>
    </footer>
  )
}

export { Footer }
