import styles from './style.module.scss';


const IconExpandRight = () => {
  return (
    <span className={styles.expandRight}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M12.5 10L13.2071 9.29289L13.9142 10L13.2071 10.7071L12.5 10ZM8.20711 4.29289L13.2071 9.29289L11.7929 10.7071L6.79289 5.70711L8.20711 4.29289ZM13.2071 10.7071L8.20711 15.7071L6.79289 14.2929L11.7929 9.29289L13.2071 10.7071Z" fill="#969EBA"/>
      </svg>
    </span>
  )
}

export { IconExpandRight }
