import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isRegisteredState } from './atom'

const useIsRegistered = () => {
  const isRegistered = useRecoilValue(isRegisteredState)
  return { isRegistered }
}
const useIsRegisteredSetter = () => {
  const setIsRegistered = useSetRecoilState(isRegisteredState)
  return { setIsRegistered }
}

export { useIsRegistered, useIsRegisteredSetter }
