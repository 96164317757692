import Link from 'next/link'

import { FC } from 'react'
import LinkButton from 'src/components/button/LinkButton'
import styles from './style.module.scss'
import { Routes } from 'src/utils/routes'
import { IconExpandRight } from 'src/components/icon/expandRight'
import { useUserSignOut } from 'src/plugins/signOut'
import { useUserUid } from 'src/store/userUid'

type Props = {
  isMenuOpen: boolean
}

const Menu: FC<Props> = ({ isMenuOpen }) => {
  const signOut = useUserSignOut()
  const { userUid } = useUserUid()
  return (
    <div
      className={`${styles.menu} ${
        isMenuOpen ? styles.menuOpen : styles.menuClose
      }`}
    >
      <div className={styles.menuInner}>
        <ul className={styles.menuList}>
          <li className={styles.menuListItem}>
            <Link href={Routes.HOME}>
              <a className={styles.menuListItemLink}>
                <span>サイトトップ</span>
                <span>
                  <IconExpandRight />
                </span>
              </a>
            </Link>
          </li>
          <li className={styles.menuListItem}>
            <Link href={Routes.ABOUT}>
              <a className={styles.menuListItemLink}>
                <span>ワフパスについて</span>
                <span>
                  <IconExpandRight />
                </span>
              </a>
            </Link>
          </li>
          <li className={styles.menuListItem}>
            <Link href={'/spot/page/1'}>
              <a className={styles.menuListItemLink}>
                <span>
                  <em className={styles.menuListItemLinkTag}>旅ナカ</em>
                  ワフパス特典一覧
                </span>
                <span>
                  <IconExpandRight />
                </span>
              </a>
            </Link>
          </li>
          <li className={styles.menuListItem}>
            <Link href="/hotel/page/1">
              <a className={styles.menuListItemLink}>
                <span className={styles.menuListItemLinkText}>
                  <em className={styles.menuListItemLinkTag}>旅マエ</em>
                  宿泊プラン一覧
                </span>
                <span>
                  <IconExpandRight />
                </span>
              </a>
            </Link>
          </li>
          <li className={styles.menuListItem}>
            <Link href={Routes.CAMPAIGN}>
              <a className={styles.menuListItemLink}>
                <span className={styles.menuListItemLinkText}>
                  <em className={styles.menuListItemLinkTag}>旅マエ</em>
                  交通キャンペーン一覧
                </span>
                <span>
                  <IconExpandRight />
                </span>
              </a>
            </Link>
          </li>
        </ul>
        {userUid ? (
          <div className={styles.menuButton}>
            <button
              className={styles.menuLogout}
              onClick={() => {
                signOut()
              }}
            >
              ログアウト
            </button>
          </div>
        ) : (
          <div className={styles.menuButton}>
            <LinkButton href={Routes.SIGN_IN} color="blue">
              ログイン・新規登録
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  )
}

export { Menu }
