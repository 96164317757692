import { doc, getDoc } from 'firebase/firestore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIsRegistered, useIsRegisteredSetter } from 'src/store/userRegistered';
import { useIsAuthenticated, useUserUid } from 'src/store/userUid';
import { db } from 'src/utils/firebase/initializeFirebase';
import { Routes } from 'src/utils/routes';

const useCheckUserRegistered = () => {
  const router = useRouter()
  const { isAuthenticated } = useIsAuthenticated()
  const { userUid } = useUserUid()
  const { isRegistered } = useIsRegistered()
  const { setIsRegistered } = useIsRegisteredSetter()

  return async () => {
    if (!userUid) {
      return
    }
    const user = await getDoc(doc(db, 'users', `${userUid}`))
    const userExists = user.exists()
    if (userExists) {
      setIsRegistered(true)
    }

    // firebaseに登録していない場合は/sign-upページに遷移させる
    if (router.pathname !== Routes.SIGN_UP && !userExists) {
      if (
        router.pathname === Routes.HOME ||
        router.pathname === Routes.SIGN_IN
      ) {
        router.push(Routes.SIGN_UP)
      }
      router.push({
        pathname: Routes.SIGN_UP,
        query: {
          redirect: router.pathname,
        },
      })
    }
  }
}

export { useCheckUserRegistered }
