import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userNameState } from './atom'

const useUserName = () => {
  const userName = useRecoilValue(userNameState)

  return { userName }
}
const useUserNameSetter = () => {
  const setUserName = useSetRecoilState(userNameState)
  return { setUserName }
}

export { useUserName, useUserNameSetter }
